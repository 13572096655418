<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="codice"
                        :name="beForm[rep].codice.label"
                        :label="beForm[rep].codice.label"
                        v-model="form[rep].codice"
                        placeholder="Inserisci un codice"
                        :rules="{ required: true }"
                        maxlength="4"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="descrizione"
                        :name="beForm[rep].descrizione.label"
                        label="Descrizione"
                        v-model="form[rep].descrizione"
                        placeholder="Inserisci una descrizione"
                        maxlength="50"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="tipo_di_movimento"
                        :name="beForm[rep].tipo_di_movimento.label"
                        :label="beForm[rep].tipo_di_movimento.label"
                        v-model="form[rep].tipo_di_movimento"
                        :options="beForm[rep].tipo_di_movimento.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('tipo_di_movimento')"
                        :disabled="hasCashEntries"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="tipologia"
                        :name="beForm[rep].tipologia.label"
                        :label="beForm[rep].tipologia.label"
                        v-model="form[rep].tipologia"
                        :options="beForm[rep].tipologia.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('tipologia')"
                        :disabled="hasCashEntries"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="causale_collegata"
                        :name="beForm[rep].causale_collegata.label"
                        label="Causale autom. collegata"
                        v-model="form[rep].causale_collegata"
                        :options="beForm[rep].causale_collegata.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="{ integer: true }"
                        :disabled="hasCashEntries"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="id_conto_prima_nota_default"
                        :name="beForm[rep].id_conto_prima_nota_default.label"
                        :label="beForm[rep].id_conto_prima_nota_default.label"
                        v-model="form[rep].id_conto_prima_nota_default"
                        :options="
                          beForm[rep].id_conto_prima_nota_default.options
                        "
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('id_conto_prima_nota_default')"
                        :disabled="hasCashEntries"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="include_produttore"
                        :name="beForm[rep].include_produttore.label"
                        :label="beForm[rep].include_produttore.label"
                        v-model="form[rep].include_produttore"
                        :options="beForm[rep].include_produttore.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="{ required: true }"
                        :disabled="hasCashEntries"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="include_compagnia"
                        :name="beForm[rep].include_compagnia.label"
                        :label="beForm[rep].include_compagnia.label"
                        v-model="form[rep].include_compagnia"
                        :options="beForm[rep].include_compagnia.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="{ required: true }"
                        :disabled="hasCashEntries"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import CustomInputs from "@/components/form/CustomInputs";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "cash_reference",
      id: this.$route.params.id,
      cash_entries: [],
      contiene_movimenti: false,
      form: {
        cash_reference: {
          causale_collegata: null,
          codice: null,
          descrizione: null,
          tipo_di_movimento: null,
          include_produttore: null,
          tipologia: null,
          id_conto_prima_nota_default: null,
        },
      },
    };
  },
  components: {
    CustomInputs,
    BaseIcon,
    BaseInput,
    BaseSelect,
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
    }),
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.$store.dispatch("auth/cash_references");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Causale Modificata`,
          });
          this.shortcut(
            "treasury-cash-reference",
            null,
            "#CashReferences",
            "filterCashReferences"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.isLoading = false;
      this.contiene_movimenti = this.entity.contiene_movimenti;
    });
  },
  computed: {
    hasCashEntries() {
      return this.contiene_movimenti;
    },
    rep() {
      return this.repository;
    },
  },
};
</script>
